button.ant-btn, button.ant-btn:active, button.ant-btn:focus {
    background-color: #0f325a36;
    border: none;
    outline: none;
}

button.ant-btn:hover {
    background-color: #0f325a99;
}

.content {
    margin: 0 auto;
}

footer {
    text-align: center;
    padding: 4vh 4%;
    background-color: #3b4449;
    color: #ffffff;
}