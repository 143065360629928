#projects {
	padding: 4%;
	background-color: #545f66;
	min-height: 50vh;
}
#projects h1 {
	color: #ffffff;
	font-size: max(125%, 2.7vmax);
}

.projectList {
	margin: 0vw 5vw !important;
}

.projectCard {
	width: min-content;
	box-shadow: 0 0 1rem 0 rgba(0, 0, 0, 0.2);
}

.projectModal {
	max-width: 80vw;
}

.cover > video,
.cover > img {
	max-width: 100%;
	max-height: 300px;
}

img.cardCover {
	width: auto;
	height: min(30vw, 300px);
	min-height: 150px;
}
