#about {
    padding: 4%;
    font-size: max(100%, 1.1vw);
}

.title > h1 {
    font-size: max(125%, 2.2vw);
    text-align: center;
}

.description {
    font-size: max(100%, 1.1vw);
    margin-bottom: 5vh;
    text-align: center;
}